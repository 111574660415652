import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import {  Observable } from 'rxjs';

import { environment } from '../environments/environment'
import { NewTransfer, Transfer, TransferListResult } from 'app/models/Transfer';


@Injectable({providedIn: 'root'})
export class TransferService {

  API_URL = environment.assetsAPIUrl;

  constructor(private _http: HttpClient) { }


  getTransfers(search: string, sort: string, page: number) : Observable<TransferListResult>{
    let params = new HttpParams();

    if(search != ""){
      params = params.append("search", search);
    }
    if(sort != ""){
      params = params.append("sort", sort);
    }
    if(page > 1){
      params =params.append("page",String(page))
    }

    return this._http.get<TransferListResult>(this.API_URL + "/api/transfers", {params})
  }
  geTransfer(transfer: string): Observable<Transfer>{
    return this._http.get<Transfer>(this.API_URL + "/api/transfers/" + transfer);
  }
  
  addTransfer(transfer: NewTransfer){
    return this._http.post(this.API_URL + "/api/transfers", transfer);
  }
/*
  getUnitAssets(unit: string): Observable<UnitAsset[]>{
    return this._http.get<UnitAsset[]>(this.API_URL + "/api/units/" + unit + "/assets");
  }

  assignAsset(unit: string, asset: string){
    return this._http.post(this.API_URL + "/api/units/" + unit + "/assets", {action: "assign", asset: asset});
  }

  removeAsset(unit: string, assignmentid: string){
    return this._http.put(this.API_URL + "/api/units/" + unit + "/assets", {action: "unassign", assignmentid: assignmentid});
  }

  getUnitStock(unit: string): Observable<UnitStock[]>{
    return this._http.get<UnitStock[]>(this.API_URL + "/api/units/" + unit + "/stock");
  }

  addUnitStock(unit: string, stock: string){
    return this._http.post(this.API_URL + "/api/units/" + unit + "/stock", {sku: stock});
  }

  updateUnitStock(unit: string, stock: string, quantity: number){
    return this._http.put(this.API_URL + "/api/units/" + unit + "/stock", {sku: stock, quantity: quantity});
  }

  deleteUnitStock(unit: string, stock: string){
    return this._http.delete(this.API_URL + "/api/units/" + unit + "/stock", {body: {sku: stock,quanity: 0}});
  }

  getUnitStockLog(unit: string, stock: string): Observable<UnitStockLog[]>{
  
    return this._http.get<UnitStockLog[]>(this.API_URL + "/api/units/" + unit + "/stock/log", {params: {sku: stock}});
  }*/
}