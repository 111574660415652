<div>
  <H2>Stock</H2>

  <div *ngIf="isLoading">
    <H2>Loading...</H2>
  </div>
  
  <div *ngIf="isDisplaying">
    <div class="keyword-wrapper">
      <input [formControl]="queryField" type="text" id="keyword" placeholder="Search for sku..." autofocus/>
    </div>
    <mat-drawer-container #listContainer class="min-height">

      <mat-drawer #editPopup class="popup" #sidePop mode="over" opened="false" [position]="pop_position">
        <button (click)="sidePop.close()" mat-button>>></button>
        <hr>

        <H3><a [routerLink]="['/stock-update/' + selectedLocation + '/' + selectedSku]">{{selectedSku}}</a></H3>
        <p>{{selectedLocation}}</p>

        <mat-label>Quantity</mat-label>

        <div *ngIf="canUpdateStock">
          <mat-form-field>
              <input matInput [(ngModel)]="selectedQuantity" id="quantity" placeholder="Enter quantity">
          </mat-form-field> 
          <button mat-raised-button aria-label="update" (click)="newQuantity()">Update</button>
        </div>

        <div *ngIf="!canUpdateStock">
          <mat-label> {{selectedQuantity}} </mat-label>
        </div>       
        
        
      </mat-drawer>

      <mat-drawer-content>
        <table mat-table [dataSource]="stock" class="mat-elevation-z8">
          <!-- Column Definitions -->
          <ng-container  matColumnDef="Image">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <div class="image" *ngIf='element.image'>
                <img-load class="thumb" image="https://dfxpressstorage.blob.core.windows.net/assetsportal/product/{{element.image}}"></img-load>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="SKU">
            <th mat-header-cell *matHeaderCellDef> SKU </th>
            <td mat-cell *matCellDef="let element" class="sku-label"> {{element.sku}}<br/><p class="sku-description-indent"><small class="sku-description">{{element.description}}</small></p></td>
          </ng-container>
          <ng-container matColumnDef="{{column}}" *ngFor="let column of locationHeaders">
            <th mat-header-cell *matHeaderCellDef><div class="header"> {{column}} </div></th>
            <td mat-cell *matCellDef="let element" (click)="setSelection(element.sku, column, element.locations[column])"> <div class="dataElement">{{element.locations[column]}}</div> </td>
          </ng-container>
        
          <!-- Header Row Definition -->
          <tr mat-header-row *matHeaderRowDef="displayHeaders"></tr>
        
          <!-- Row Definition-->
          <tr mat-row *matRowDef="let row; columns: displayHeaders;"></tr>
        </table>
      </mat-drawer-content>

    </mat-drawer-container>
    <nav>
      <ul class="pagination">
        <li class="page-item" *ngFor="let p of pageLinks" >
          <a class="page-link" (click)="navigatePage(p+1)">{{p+1}}</a>
        </li>
      </ul>
    </nav>
  </div>

  <div *ngIf="isError">
    <H2>Failed to Load...</H2>
  </div>
</div>