<H2>Transfers</H2>

<div *ngIf="isLoading">
  <H2>Loading...</H2>
</div>

<div *ngIf="isDisplaying">

  <mat-drawer-container>

    <mat-drawer #newPopup class="popup" #newPop mode="over" opened="false" [position]="pop_position">
      <button mat-flat-button (click)="newPop.close()" type="button" class="right"><<</button>
      <hr>
      <div>
        <newTransferForm> </newTransferForm>
      </div>
    </mat-drawer>

    <mat-drawer-content class="min-table-height">
      <button (click)="newPop.open()" mat-flat-button>+</button>
      <transferList-item *ngFor="let transfer of transfers" [transfer]="transfer">  </transferList-item>
    </mat-drawer-content>
  </mat-drawer-container> 

  <nav>
    <ul class="pagination">
      <li class="page-item" *ngFor="let p of pageLinks">
        <a class="page-link"  (click)="navigatePage(p+1)">{{p+1}}</a>
      </li>
    </ul>
  </nav>
</div>

<div *ngIf="isError">
  <H2>Error Loading Asset</H2>
</div>