import { Component, OnInit, Input } from '@angular/core';

import { Transfer } from 'app/models/Transfer';

@Component({
  selector: 'transferList-item',
  templateUrl: './transferList-item.component.html',
  styleUrls: ['./transferList-item.component.css']
})
export class TransferListItemComponent implements OnInit {

  @Input() transfer: Transfer;

  constructor( ) { }

  ngOnInit() {
    
    //Log this.transfer to the console
    console.log(this.transfer);
   }
}
