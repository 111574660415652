<div class="container">
  <div class="trandferid">
      <a routerLink="/transfers/{{transfer.transferID}}" routerLinkActive='active'>{{transfer.transferID}}</a>
  </div>
  <div class="description">
      {{transfer.description}}
  </div>
  <div class="state">
      {{transfer.state}}
  </div>
  <div class="fromTo">
      {{transfer.fromLocation}} - {{transfer.toLocation}}
  </div>
  <div class="createdBy">
    {{transfer.createdBy}}
  </div>
  <div class="createdOn">
    {{transfer.createdOn}}
  </div>
</div>

