import {AssetService} from '../../../services/assets.service';
import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NewProduct } from '../../models/NewProduct';
import { ContractorSignin, newContractorSignin } from '../../models/ContractorSignin';
import { ActivatedRoute } from '@angular/router';
import { ServiceRequestLog } from 'app/models/ServiceRequestLog';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, isEmpty, switchMap } from 'rxjs/operators';
import { UserService } from 'services/user.service';
import { error } from 'console';
import { environment, sites } from 'environments/environment';
import { NewTransfer, Transfer } from 'app/models/Transfer';
import { TransferService } from 'services/assets.transfer.service';

@Component({
  selector: 'newTransferForm',
  templateUrl: './newTransferForm.component.html',
  styleUrls: ['./newTransferForm.component.css']
})
export class NewTransferFormComponent implements OnInit {

  newTransfer = new NewTransfer();

  @Output() onNewEvent: EventEmitter<any> = new EventEmitter();
  showServiceNumberField = true;
  showSigninForm: boolean =  true;
  showSigninSucess: boolean = false;
  signinSuccessDetails: ContractorSignin;

  srValid: boolean = false;

  constructor(
    private _assetTransferService: TransferService,
    private _userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {

  }

  getValue(event: Event): string{
    return (event.target as HTMLInputElement).value;
  }

  onSubmit(newUnitForm: NgForm){
    
    this.newTransfer.FromLocation = 'BNE';
    this.newTransfer.ToLocation = 'SYD';
    this.newTransfer.Description = 'Test 1002'

    this._assetTransferService.addTransfer(this.newTransfer)
      .subscribe(this.newUnit);/*data => {
        //this.signinSuccessDetails = data;

        this.newTransfer = new NewTransfer();
        newUnitForm.reset();
        this.onNewEvent.emit(null);
      });*/
  }

  newUnit = {
    next: (value: any) => {
      this.newTransfer = new NewTransfer();
      //newUnitForm.reset();
      this.onNewEvent.emit(null);
    },
    error: (err: any) => {
      console.log(err);
    }
  };
}
