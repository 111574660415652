import { AssetService } from '../../../services/assets.service';
import { Component, OnInit,ViewChild,Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder,Validators } from '@angular/forms';
import { Asset,AssetProperty,AssetListResult } from '../../models/Asset'
import { ProductSearchComponent } from '../../product-search/product-search.component';
import { MatDialog } from '@angular/material/dialog';
import { assetPropertyNewPopupComponent } from 'app/popups/assetPropertyNewPopup/assetpropertyNewPopup.component';
import { UserService } from 'services/user.service';
import { get } from 'http';
import { assignAssetToUnitPopupComponent } from 'app/popups/AssignAssetToUnitPopup/assignAssetToUnitPopup.component';
import { AssetUnitService } from 'services/assets.units.service';
import { Transfer, TransferListResult } from 'app/models/Transfer';
import { TransferService } from 'services/assets.transfer.service';
import { version } from 'process';

enum TransferListState{
  Loading,
  Displaying,
  Error
}

@Component({
  selector: 'transfer-list.page',
  templateUrl: './transfer-list.page.component.html',
  styleUrls: ['./transfer-list.page.component.css']
})

export class TransferListPageComponent implements OnInit {

  pageState: TransferListState = TransferListState.Loading;
  public transfers: Array<Transfer> = [];

  public search: string = "";
  public sort: string = "";
  public page: number = 1;

  showAddTransferForm = false;
  public pageLinks: Array<number> = []

  constructor(
    private _transferService: TransferService,
    private _user: UserService,
    public dialogProperty: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public dialogAssignAsset: MatDialog
  ){ }

  ngOnInit() {
    this.getTransfers();
  }

  getTransfers(){
    this.pageState = TransferListState.Loading;
    this._transferService.getTransfers(this.search,this.sort,this.page).subscribe(this.readTransfers);
  }

  private readTransfers = {
    next: (_transferList: TransferListResult) => {
      this.transfers = _transferList.transfers;

      this.pageLinks = [];
      for (let i = 0; i < _transferList.totalPages; i++) {
        this.pageLinks.push(i);
      }

      this.pageState = TransferListState.Displaying;

      this.updateRoute();
    },
    error: (error: any) => {
      this.pageState = TransferListState.Error;
    }
  }

  updateRoute( ){
    var queryParams = {};
    if (this.search != "" ){queryParams['search'] = this.search;}
    if (this.sort != "" ){queryParams['sort'] = this.sort;}
    if (this.page > 1){queryParams['page']=this.page;}
    this.router.navigate(['/transfers'], {queryParams: queryParams});
  }

  navigatePage(page:number){
    this.page = page;
    this.getTransfers();
  }

  get isLoading(){ return this.pageState == TransferListState.Loading;} 
  get isError(){ return this.pageState == TransferListState.Error; }
  get isDisplaying(){ return this.pageState == TransferListState.Displaying; }
}

