import {AssetService} from '../../../services/assets.service';
import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NewProduct } from '../../models/NewProduct';
import { ContractorSignin, newContractorSignin } from '../../models/ContractorSignin';
import { ActivatedRoute } from '@angular/router';
import { ServiceRequestLog } from 'app/models/ServiceRequestLog';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, isEmpty, switchMap } from 'rxjs/operators';
import { UserService } from 'services/user.service';
import { error } from 'console';

enum ServiceRequestFormState {
  Capture,
  Submitted,
  Confirm,
  Error
}

@Component({
  selector: 'ServiceRequestLogEntryForm',
  templateUrl: './serviceRequestLogEntryForm.component.html',
  styleUrls: ['./serviceRequestLogEntryForm.component.css']
})

export class ServiceRequestLogEntryFormComponent implements OnInit {

  LogEntry = new ServiceRequestLog();
  public formState: ServiceRequestFormState = ServiceRequestFormState.Capture;
  form: NgForm;

  @Output() onNewEvent: EventEmitter<any> = new EventEmitter();
  @Input() target: string = null;
  @Input() serviceRequest: string = null;
  showServiceNumberField = true;
  showSigninForm: boolean =  true;
  showSigninSucess: boolean = false;
  signinSuccessDetails: ContractorSignin;

  srValid: boolean = false;

  constructor(
    private _assetService: AssetService,
    private _userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.LogEntry.action = "Sign In"
    const username = this._userService.getUsername();
    if(username != ""){
      this.LogEntry.name = username;
      if(username.endsWith("@dfxpress.online"))
      {
        this.LogEntry.company = "Direct Freight Express"
      }
      
    }

    if(this.serviceRequest != null){
      this.LogEntry.serviceRequest = this.serviceRequest;
      
      //If the service request number has been provided hide the serviceNumber field
      this.showServiceNumberField = false;
      this.validate(this.serviceRequest)
    }
  }
  

  validate(srNumber: string){
    if(srNumber.length == 0) {
      this.srValid = false;
      return;
    }

    this._assetService.validateServiceRequests(srNumber)
      .subscribe(data =>{
        this.srValid = data.valid;
        console.log(this.srValid);  
      },
      error => {this.srValid = false;})
  }

  getValue(event: Event): string{
    return (event.target as HTMLInputElement).value;
  }

  onSubmit(newSigninForm: NgForm){
    this.form = newSigninForm;
    this.LogEntry.target = this.target;
    this._assetService.addServiceRequestLogEvent(this.LogEntry)
      .subscribe(this.submitLog);
    this.formState = ServiceRequestFormState.Submitted;
  }

  private submitLog = {
    next: (data) => {
      //this.signinSuccessDetails = data;
      this.formState = ServiceRequestFormState.Confirm;
      this.onNewEvent.emit(null);
    },
    error: (error: any) => {
      this.formState = ServiceRequestFormState.Error;
    }
  } 

  onReset(newSigninForm: NgForm){
    this.LogEntry = new ServiceRequestLog();
    this.form.reset();
    this.formState = ServiceRequestFormState.Capture;
  }

  get isCapture() { return this.formState == ServiceRequestFormState.Capture; }
  get isSubmitted() { return this.formState == ServiceRequestFormState.Submitted; }
  get isConfirm() { return this.formState == ServiceRequestFormState.Confirm; }
  get isError() { return this.formState == ServiceRequestFormState.Error; }
}
