
<nav class="navbar navbar-expand-lg">
    <div class="container-fluid horrible-background">

        <a class="navbar-brand" href="#">DFX</a>

        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbarSupportedContent" class="collapse navbar-collapse">
            <ul *ngIf="loginDisplay" class="navbar-nav me-auto" >
                <!--li class="nav-item"><a class="nav-link" routerLink="/signin" routerLinkActive='active'>Site Signin</a> </li-->
                <li class="nav-item"><a class="nav-link" routerLink="/service" routerLinkActive='active'>Service Requests</a></li> 
                <li *ngIf="isEngineer" class="nav-item"><a class="nav-link" routerLink="/products" routerLinkActive='active'>Products</a> </li>
                <li *ngIf="isEngineer || _userService.hasGroup('Maintenance')" class="nav-item"><a class="nav-link" routerLink="/stock" routerLinkActive='active'>Stock</a></li>
                <li *ngIf="isEngineer || _userService.hasGroup('Maintenance')" class="nav-item"><a class="nav-link" routerLink="/assets" routerLinkActive='active'>Assets</a></li>
                <li *ngIf="isEngineer || _userService.hasGroup('Maintenance')" class="nav-item"><a class="nav-link" routerLink="/units" routerLinkActive='active'>Units</a> </li>
                <li *ngIf="isEngineer || _userService.hasGroup('Maintenance')" class="nav-item"><a class="nav-link" routerLink="/transfers" routerLinkActive='active'>Transfers</a> </li>
                
            </ul>
 
            <ul class="navbar-nav me-2">
                <li *ngIf="!loginDisplay" class="nav-item"> <button class="btn btn-default navbar-btn" (click)="login()">Login</button></li>
                <li *ngIf="loginDisplay" class="nav-item navbar-text"> <a routerLink="/profile" routerLinkActive='active'>{{username}}</a> </li>
                <li *ngIf="loginDisplay" class="nav-item"> <button class="btn btn-default navbar-btn" (click)="logout()" >Logout</button></li>
                <li><a href="https://dfxpress.sharepoint.com/sites/Learn/SitePages/DFX-Portal-Documantation.aspx" target="_blank" class="btn btn-light" role="button" rel="noopener noreferrer">Help</a></li>
            </ul>
        </div>
    </div>
</nav>

<div class="container MT-5">
<router-outlet></router-outlet>
</div>