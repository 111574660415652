<div *ngIf='isCapture' class="form-spacing">
  <h2>Service Log Entry</h2>
  <form #newSigninForm="ngForm" (ngSubmit)="onSubmit(newSigninForm)" novalidate>
    <div class="form-floating">
      <input required 
            class="stl-input form-control"
            type="text"
            #contractorName="ngModel"
            [class.is-invalid]="contractorName.invalid && contractorName.touched"
            [(ngModel)]="LogEntry.name"
            name="Name"
            id="'name"
            placeholder="Name">
      <label for="name">Name / Username</label>
      <small [class.hide-me]="contractorName.valid || contractorName.untouched">Name cannot be empty</small>
    </div>
    <div class="form-floating">
      <input required 
            class="stl-input form-control"
            type="text"
            #company="ngModel"
            [class.is-invalid]="company.invalid && company.touched"
            [(ngModel)]="LogEntry.company"
            name="company"
            id="'company"
            placeholder="Company">
      <label for="company">Company</label>
      <small [class.hide-me]="company.valid || company.untouched">Manufacturer cannot be empty</small>
    </div>
    <div class="form-floating">
      <select class="form-select"
              
              [(ngModel)]="LogEntry.action"
              name="action"
              id="action">
        <!--option selected>Select Action</option-->
        <option selected value="Sign In">Sign In</option>
        <option value="Sign Out">Sign Out</option>
        <option value="Information">Information</option>
      </select>
      <label for="action">Action</label>
    </div> 
    <div class="form-floating">
      <textarea
            required
            class="stl-input form-control"
            rows = "3"
            type="text"
            #description="ngModel"
            [class.is-invalid]="description.invalid && description.touched"
            [(ngModel)]="LogEntry.description"
            name="description"
            id="description"
            placeholder="Description"></textarea>
      <label for="description">Description</label>
      <small [class.hide-me]="description.valid || description.untouched">Description cannot be empty</small>
    </div>
    <div *ngIf="showServiceNumberField == true" class="form-floating">
      <input
            required
            class="stl-input form-control"
            type="text"
            #serviceNumber="ngModel"
            [class.is-invalid]="serviceNumber.invalid && serviceNumber.touched"
            [(ngModel)]="LogEntry.serviceRequest"
            name="serviceNumber"
            id="serviceNumber"
            placeholder="Service Number"
            (keyup)="validate(getValue($event))">
      <label for="serviceNumber">Service Number</label>
      <small *ngIf ="!srValid">Enter a valid service request number</small>
    </div>
    <!--span>{{newSignin.site}}</span-->
    <button type="submit" class="btn btn-secondary" [disabled]="!srValid">Submit</button> <!--{{srNumberValidator$}}_-->
    <div *ngIf="signinSuccessDetails">
      {{signinSuccessDetails.contractorName}} sucessfully signed in to DFE {{signinSuccessDetails.site}} at {{ signinSuccessDetails.time | date : 'EEEE, MMMM d, h:mm:ss' }}. 
    </div>
  </form>
</div>

<div *ngIf="isSubmitted">
  Logging your service request action
</div>

<div *ngIf="isConfirm">
  Service request action logged
  <button (click)="onReset()" title="Reset Form">Reset</button>
</div>
