<div class="form-spacing">
  <h2>New Transfer</h2>
  <form #newUnitForm="ngForm" (ngSubmit)="onSubmit(newUnitForm)" novalidate>
    <div class="form-floating">
      Add new Trnsfer Form Here!
      <!--input required 
            class="stl-input form-control"
            type="text"
            #unit="ngModel"
            [class.is-invalid]="unit.invalid && unit.touched"
            [(ngModel)]="newTransfer.fromLocation"
            name="Unit"
            id="unit"
            placeholder="Name">
      <label for="name">Name</label>
      <small [class.hide-me]="unit.valid || unit.untouched">Name cannot be empty</small>
    </div>
    <div class="form-floating">
      <input required 
            class="stl-input form-control"
            type="text"
            #description="ngModel"
            [class.is-invalid]="description.invalid && description.touched"
            [(ngModel)]="newUnit.description"
            name="description"
            id="description"
            placeholder="Description">
      <label for="description">Description</label>
    </div>
    <div class="form-floating">
      <select class="form-select" title="Model"
              [(ngModel)]="newUnit.model"
              name="model"
              id="model">
        <option value="conveyor">Conveyor</option>
        <option value="boom">Extendable Boom</option>
        <option value="carousel">Carousel</option>
        <option value="parcel scanner">Pallet Scanner</option>
        <option value="pallet scanner">Parcel Scanner</option>
        <option value="store">Store</option>
      </select>
      <label for="model">Model</label>
    </div>
    <div class="form-floating">
      <select class="form-select" title="Location"
              [(ngModel)]="newUnit.location"
              name="location"
              id="location">
        <option value="Sydney">Sydney</option>
        <option value="Kemps Creek">Kemps Creek</option>
        <option value="Melbourne">Melbourne</option>
        <option value="Dandenong">Dandenong</option>
        <option value="Brisbane">Brisbane</option>
        <option value="Adelaide">Adelaide</option>
        <option value="Perth">Perth</option>
        <option value="Gold Coast">Gold Coast</option>
        <option value="Newcastle">Newcastle</option>
        <option value="Canberra">Melbourne</option>
        <option value="Sunshine Coast">Sunshine Coast</option>
        <option value="Townsville">Townsville</option>
      </select>
      <label for="locaiton">Location</label-->
    </div>

    <button type="submit" class="btn btn-secondary">Add</button>
    <div *ngIf="signinSuccessDetails">
      {{signinSuccessDetails.contractorName}} sucessfully signed in to DFE {{signinSuccessDetails.site}} at {{ signinSuccessDetails.time | date : 'EEEE, MMMM d, h:mm:ss' }}. 
    </div>
  </form>
</div>
