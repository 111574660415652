export class Transfer{
    transferID: string;
    state: string;
    fromLocation: string;
    toLocation: string;
    description: string;
    createdBy: string;
    createdOn: Date;
}

export class TransferListResult{
    transfers: Array<Transfer>;
    totalPages: number;
    page: number;
    manufacturer: string;
    search: string;
    message: string;
}

export class NewTransfer{
    FromLocation: string;
    ToLocation: string;
    Description: string;
}